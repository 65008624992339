import './add_jquery'
import "@hotwired/turbo-rails"
require("jquery-ui/ui/widgets/sortable");
require ('jquery-ui-touch-punch');

console.log('Hesalite started...')

window.photoGallery = {
  featuredPhotos: [],
  thumbPhotos: [],

  init: function() {
    var root = $('#photo-gallery');
    if(root) {
      photoGallery.featuredPhotos = root.find('img.featured');
      photoGallery.thumbPhotos = root.find('img.thumb');
      photoGallery.attachClicks();
    }
  },

  attachClicks: function() {
    photoGallery.thumbPhotos.on('click', function() {
      var id = $(this).attr('data-id');
      photoGallery.showImage(id);
    });
  },

  showImage: function(id) {
    photoGallery.featuredPhotos.each(function() { 
      $(this).addClass('dn'); 
      if ($(this).attr('data-id') == id) {
        $(this).removeClass('dn'); 
      }
    });

    photoGallery.thumbPhotos.each(function() { 
      $(this).addClass('o-50');
      $(this).removeClass('pointer');  
      if ($(this).attr('data-id') != id) {
        $(this).removeClass('o-50'); 
        $(this).addClass('pointer'); 
      }
    });
  }
}

function initMobileMenu(toggle, menu) {
  menu.classList.add('mobile-menu-closed');
  ['click', 'touchstart'].forEach(function(eventName) {
    toggle.addEventListener(eventName, function(evt) {
      evt.preventDefault();
      menu.classList.toggle('mobile-menu-open');
      menu.classList.toggle('mobile-menu-closed');
    });
  });
}

function deselectAllTabs() {
  $('.tab').each(function() { 
     $(this).removeClass('selected'); 
     $('#' + $(this).attr('data-target')).addClass('dn');
  });
}

function setupTabs() {
  $('.tab').each(function() { 
    var tab = this;

    ['click', 'touchstart'].forEach(function(eventName) {
      tab.addEventListener(eventName, function(evt) {
        evt.preventDefault();
        deselectAllTabs();
        $(evt.target).addClass('selected');
        $('#' + $(evt.target).attr('data-target')).removeClass('dn');
        var newUrl = $(evt.target).attr('data-url');

        if (newUrl) {
          window.history.pushState({}, '', newUrl);
        }
      });
    });
  });
}

function setupPhotoSorting() {
  $("#attached-photos .photos").sortable({
    update: function(e, ui) {
      $.ajax({
        url: $(this).data("url"),
        type: "PATCH",
        data: $("#attached-photos .photos").sortable('serialize'),
        headers: {
          'X-Transaction': 'PATCH Example',
          'X-CSRF-Token': $('meta[name="csrf-token"]').attr('content')
        },
      });
    }
  });
}

function setupRenderImagePreview() {
  var input = $('.file-input');
  var previewContainer = $('.image-preview');

  if (input && previewContainer) {

    input.change(function(e) {
      var input = e.target;
      if (input.files && input.files[0]) {
        var reader = new FileReader();

        reader.onload = function (e) {
            var image = new Image();
                image.src = e.target.result;

            image.onload = function() {
              var imageWidth = this.width;
              var imageHeight = this.height;
              
              previewContainer
                .attr('src', e.target.result)
                .width(200)
                .height((200 * imageHeight) / imageWidth);
            };
        };

        reader.readAsDataURL(input.files[0]);
      }
    });
  }
}

$(document).ready(function() { 
  photoGallery.init();

  var toggle = document.getElementById('mobile-profile-toggle');
  var menu = document.getElementById('profile-nav-mobile');
  if (toggle && menu) {
    initMobileMenu(toggle, menu); 
  }

  setupTabs();
  setupPhotoSorting();
  setupRenderImagePreview();
});

document.addEventListener("turbo:render", function(e) {
  photoGallery.init();

  var toggle = document.getElementById('mobile-profile-toggle');
  var menu = document.getElementById('profile-nav-mobile');
  if (toggle && menu) {
    initMobileMenu(toggle, menu); 
  }

  setupTabs();
  setupPhotoSorting();
  setupRenderImagePreview();
})


